import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['addButton', 'replaceButton', 'form']
  static values = { url: String }

  addProducts() {
    this.addButtonTarget.value = true
    this.submit()
  }

  replaceProducts() {
    this.replaceButtonTarget.value = true
    this.submit()
  }

  async submit() {
    const formData = new FormData(this.formTarget)
    await post(this.urlValue, {
      body: formData
    })
  }

}
