import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'
import { csrfToken } from '../../utils.js'
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  static targets = ['id', 'icon']
  static values = { url: String }
 
  async remove() {
    await this.makeRequest()
    location.reload()
  }

  async makeRequest() {
    const formData = new FormData()
    await post(this.urlValue, { body: formData })
  }
}
