import { Controller } from '@hotwired/stimulus';
import { isThisQuarter, isThursday } from 'date-fns';

export default class extends Controller {
  static targetGroups = {
    'alwaysRequired': ['dataType', 'cron'],
    'dataTypeSpecific': [
      'url', 'monitoringClient', 'version', 'email', 'password', 'shop', 'autoGenerate',
      'sortimentReportField', 'sourceName', 'transactionsHistory', 'monitoringTag'
    ],
    'customTime': ['customTime', 'hours', 'minutes'],
    'ga4': ['ga4AccountId', 'ga4PropertyId', 'ga4AuthId'],
    'shopify': ['shopifyTransactionsConnector', 'shopifyProductsConnector']
  }

  static targets = Object.values(this.targetGroups).reduce((acc, val) => acc.concat(val), [])

  connect() {
    this.selectDataType()
  }

  selectDataType() {
    this.resetCron()
    this.hideGa4Properties()

    switch(this.dataTypeTarget.value) {
      case 'product_feed':
        this.adjustCron('Každou lichou hodinu', '0 1-23/2 * * *', true)
        this.showOnly(['url'])
        break;

      case 'google_shopping_feed':
        this.adjustCron('Každou lichou hodinu', '0 1-23/2 * * *', true)
        this.showOnly(['url'])
        break;

      case 'transaction_feed':
        this.adjustCron('Každý den ve 02:00', '0 2 * * *')
        this.adjustCron('Každý den ve 05:00', '0 5 * * *')
        this.showOnly(['url'])
        break;

      case 'sortiment_report':
        this.adjustCron('Každý den ve 02:00', '0 2 * * *')
        this.showOnly(['version', 'email', 'shop', 'password', 'autoGenerate', 'sortimentReportField'])
        break;

      case 'heureka_feed':
        this.adjustCron('Každý den v 03:00 a 12:00', '0 3,12 * * *')
        this.showOnly(['version'])
        break;

      case 'price_check':
        this.adjustCron('Každý den v 03:00 a 12:00', '0 3,12 * * *')
        this.showOnly(['url', 'sourceName'])
        break;

      case 'monitoring':
        this.adjustCron('Každou lichou hodinu', '0 1-23/2 * * *')
        this.adjustCron('Každou hodinu', '0 * * * *')
        this.adjustCron('Každou půlhodinu', '*/30 * * * *')
        this.showOnly(['monitoringClient', 'monitoringTag'])
        this.selectMonitoringClient() 
        break;

      case 'ga4':
        this.showGa4Properties()
        this.adjustCron('Každý den v 03:00', '0 3 * * *')
        this.showOnly(['ga4AuthId', 'ga4AccountId', 'ga4PropertyId'])
        break;
      
      case 'shopify_product_feed':
        this.adjustCron('Každou lichou hodinu', '0 1-23/2 * * *', true)
        this.showOnly(['shopifyProductsConnector'])
        break;

      case 'shopify_transaction_feed':
        this.adjustCron('Každý den ve 02:00', '0 2 * * *')
        this.adjustCron('Každý den ve 05:00', '0 5 * * *')
        this.showOnly(['shopifyTransactionsConnector', 'transactionsHistory'])
        break;

      default:
        this.adjustCron('Každý den v 03:00 a 12:00', '0 3,12 * * *')
        this.adjustCron('Každý den ve 03:30', '30 3 * * *')
        this.showOnly(['url'])
    }

    this.selectFreqency()
  }

  adjustCron(text, cronLine, addOncePerDayOption = false) {
    let node = document.createElement("option")
    node.text = text
    node.value = cronLine
    this.cronTarget.prepend(node)

    if (this.cronTarget.querySelector(`option`).text == '') {
      this.cronTarget.removeChild(this.cronTarget.querySelector(`option`))
    }

    if (addOncePerDayOption) this.addOncePerDayOption()
  }

  resetCron() {
    this.cronTarget.querySelectorAll('option').forEach(option => { option.remove() })
  }

  hideItems(items) {
    items.forEach(target => {
      target.classList.add('hidden')
      this.disableAllInputs(target)
    })
  }

  showItems(items) {
    items.forEach(target => {
      target.classList.remove('hidden')
      this.enableAllInputs(target)
    })
  }

  showOnly(items) {
    Object.entries(this.constructor.targetGroups).forEach(entry => {
      const [group, targets] = entry
      if(group === 'alwaysRequired') {
        this.showItems(targets.map(name => this[`${name}Target`]))
      } else {
        const toHide = [], toShow = [];
        targets.forEach(name => {
          const target = this[`${name}Target`]
          items.includes(name) ? toShow.push(target) : toHide.push(target)
        })
        this.showItems(toShow)
        this.hideItems(toHide)
      }
    });
  }

  showGroup(group) {
    this.showItems(this.constructor.targetGroups[group].map(name => this[`${name}Target`]))
  }

  hideGroup(group) {
    this.hideItems(this.constructor.targetGroups[group].map(name => this[`${name}Target`]))
  }

  hideGa4Properties() {
    document.getElementById('ga4_properties').classList.add('hidden')
    this.disableAllInputs(document.getElementById('ga4_properties'))
  }

  showGa4Properties() {
    document.getElementById('ga4_properties').classList.remove('hidden')
    this.enableAllInputs(document.getElementById('ga4_properties'))
  }

  disableAllInputs(item) {
    item.querySelectorAll('input, select').forEach(input => { input.disabled = true })
  }

  enableAllInputs(item) {
    item.querySelectorAll('input, select').forEach(input => { input.disabled = false })
  }

  setCustomCronTime() {
    const opt = document.getElementById('once_per_day')
    opt.value = [this.minutesTarget.value, this.hoursTarget.value, '* * *'].join(' ')
  }

  selectFreqency() {
    if (['product_feed', 'shopify_product_feed'].includes(this.dataTypeTarget.value) && this.cronTarget.options[this.cronTarget.selectedIndex].text == 'Jednou denně') {
      this.hoursTarget.removeAttribute('disabled')
      this.minutesTarget.removeAttribute('disabled')
      this.showGroup('customTime')
    } else {
      this.hoursTarget.setAttribute('disabled', true)
      this.minutesTarget.setAttribute('disabled', true)
      this.hideGroup('customTime')
    }
  }

  selectMonitoringClient() {
    const frame = document.querySelector("#monitoringTags")
    const value = this.monitoringClientTarget.querySelector('select').value
    frame.src = `${frame.dataset.baseUrl}&monitoring_client_id=${value}`
    frame.reload()
  }

  addOncePerDayOption() {
    const opt = document.createElement("option")

    opt.text = 'Jednou denně';
    opt.id = 'once_per_day'
    this.hoursTarget.value = 0
    this.minutesTarget.value = 0
    opt.value = [this.minutesTarget.value, this.hoursTarget.value, '* * *'].join(' ')

    this.cronTarget.appendChild(opt);
  }

  authChange(event) {
    const selectedValue = event.target.value
    const instanceId = this.data.get("instanceIdValue")
    const newUrl = `/admin/instances/${instanceId}/ga4_properties?ga4_auth_record_id=${selectedValue}`
    const turboFrame = document.querySelector('[data-properties="ga4PropertiesFrame"]')

    if (turboFrame) {
      turboFrame.classList.add('hidden')
      turboFrame.src = newUrl
    } else {
      console.error("Turbo Frame with ID 'ga4PropertiesFrame' not found.")
    }
  }

  setConnectorId(event) {
    const selectedValue = event.target.value
    console.log(selectedValue)
    console.log(this.shopifyProductsConnectorTarget.attributes)

    this.shopifyProductsConnectorTarget.querySelectorAll('option').forEach(option => { 
      if ( Number(option.value) == selectedValue) {
        option.setAttribute('selected', true)
      }
    })
    console.log(this.shopifyProductsConnectorTarget.querySelectorAll('option'))
  }
}
