import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = [ 'dimension', 'data', 'template', 'body', 'count', 'spinner', 'form', 'productsSpinner' ]
  static values = { url: String}

  connect() {
    useDebounce(this, { wait: 10 })
  }

  show(event) {
    if (event.target.value != '') {
      this.dataTarget.classList.remove('hidden')
    } else {
      this.dataTarget.classList.add('hidden')
    }
  }

}
