
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "button"];
  static values = { hide: String, show: String }

  connect() {
    this.limit = 5;
    this.update();
  }

  toggle() {
    this.itemTargets.forEach((el, index) => {
      if (index >= this.limit) {
        el.classList.toggle("hidden");
      }
    });
  
    if (this.buttonTarget.innerText === this.showValue) {
      this.buttonTarget.innerText = this.hideValue;
    } else {
      this.buttonTarget.innerText = this.showValue;
    }
  }

  update() {
    this.itemTargets.forEach((el, index) => {
      if (index >= this.limit) {
        el.classList.add("hidden");
      }
    });
  }
}
