import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['products', 'overview', 'productsButton', 'overviewButton', 'overviewCountButton', 'productsCountButton']

  connect() {
    document.addEventListener('turbo:frame-load', this.onFrameLoad)
  }
  
  disconnect() {
    document.removeEventListener('turbo:frame-load', this.onFrameLoad)
  }

  showOverview() {
    this.productsButtonTarget.classList.remove("text-gray-900", "shadow-sm", "bg-white")
    this.productsButtonTarget.classList.add("text-gray-700")

    this.overviewButtonTarget.classList.remove("text-gray-700")
    this.overviewButtonTarget.classList.add("text-gray-900", "shadow-sm", "bg-white")

    this.productsTarget.classList.add('hidden')
    this.overviewTarget.classList.remove('hidden')
  }

  showProducts() {
    this.overviewButtonTarget.classList.remove("text-gray-900", "shadow-sm", "bg-white")
    this.overviewButtonTarget.classList.add("text-gray-700")

    this.productsButtonTarget.classList.remove("text-gray-700")
    this.productsButtonTarget.classList.add("text-gray-900", "shadow-sm", "bg-white")


    this.overviewTarget.classList.add('hidden')
    this.productsTarget.classList.remove('hidden')
  }

  onFrameLoad = (event) => {
    if (event.target.id === "monitoring_competitors_overview") {
      const pagyInfo = event.target.querySelector(".pagy-info")
  
      if (pagyInfo) {
        const strong = pagyInfo.querySelector("strong")
        if (strong) {
          const total = parseInt(strong.textContent.trim(), 10)
          this.overviewCountButtonTarget.textContent = total
        }
      }
    }
  
    if (event.target.id === "monitoring_products") {
      const pagyInfo = event.target.querySelector(".pagy-info")
  
      if (pagyInfo) {
        const strong = pagyInfo.querySelector("strong")
        if (strong) {
          const total = parseInt(strong.textContent.trim(), 10)
          this.productsCountButtonTarget.textContent = total
        }
      }
    }
  }
}
