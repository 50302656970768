import { Controller } from '@hotwired/stimulus';
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

export default class extends Controller {
  static targets = ['source']

  connect() {
    this.selectSource()
  }

  disconnect() {
    $('.basic-multiple-select').select2('destroy')
  }

  enableSelect2() {
    $('.basic-multiple-select').select2({
      placeholder: "Select an option",
      debug: true,
      tags: true
    });
  }

  selectSource() {
    $('.basic-multiple-select').select2('destroy')
    const frame = document.querySelector("#categories_for_select")
    const value = this.sourceTarget.querySelector('select').value
    frame.src = `${frame.dataset.baseUrl}&source=${value}`
    frame.reload()
  }
}
