import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['spinner', 'link']

  initialize() {
    this.boundHideSpinner = this.hideSpinner.bind(this)
  }

  connect() {
    document.addEventListener('turbo:frame-load', this.boundHideSpinner)
  }

  disconnect() {
    document.removeEventListener('turbo:frame-load', this.boundHideSpinner)
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
    this.linkTarget.classList.add('hidden')
    this.linkTarget.setAttribute("aria-disabled", "true");
    this.linkTarget.setAttribute("tabindex", "-1");
    this.linkTarget.classList.add("disabled");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
    this.linkTarget.classList.remove('hidden')
    this.linkTarget.removeAttribute("aria-disabled")
    this.linkTarget.removeAttribute("tabindex")
    this.linkTarget.classList.remove("disabled")
  }
}