import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'categoryGlobalFilter',
    'storeGlobalFilter',
    'producerGlobalFilter',
    'colorGlobalFilter',
    'categoryFilter',
    'storeFilter',
    'producerFilter',
    'colorFilter',
    'categoriesResetFilter',
    'storesResetFilter',
    'producersResetFilter',
    'colorsResetFilter',
    'submitButton',
    'monitoringStoreFilter',
    'monitoringStoresResetFilter',
    'categoriesCountFilter',
    'storesCountFilter',
    'colorsCountFilter',
    'producersCountFilter',
    'monitoringCountResetFilter'
  ]

  removeFilter(event) {
    const label = event.target.closest('label');
    const input = label.querySelector('input[type="hidden"]');

    input.remove();
    this.submit();
  }

  removeAllFilters() {
    if (this.hasCategoryGlobalFilterTarget){

      this.categoryGlobalFilterTarget.value = ''
    }

    if (this.hasProducerGlobalFilterTarget){
      this.producerGlobalFilterTarget.value = ''
    }

    if (this.hasColorGlobalFilterTarget){
      this.colorGlobalFilterTarget.value = ''
    }

    if (this.hasStoreGlobalFilterTarget){
      this.storeGlobalFilterTargets.forEach(storeTarget => {
        storeTarget.remove()
      })
    }
  
    this.submit()
  }

  removeCategoryFilter(){
    this.categoryFilterTargets.forEach(radioButton => {
      radioButton.checked = false
    })

    this.categoriesResetFilterTarget.classList.add('hidden')
    this.categoriesCountFilterTarget.classList.add('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }

  removeProducerFilter(){
    this.producerFilterTargets.forEach(radioButton => {
      radioButton.checked = false
    })

    this.producersResetFilterTarget.classList.add('hidden')
    this.producersCountFilterTarget.classList.add('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }

  removeColorFilter(){
    this.colorFilterTargets.forEach(radioButton => {
      radioButton.checked = false
    })

    this.colorsResetFilterTarget.classList.add('hidden')
    this.colorsCountFilterTarget.classList.add('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }

  removeStoreFilter(){
    this.storeFilterTargets.forEach(radioButton => {
      radioButton.checked = false
    })

    this.storesResetFilterTarget.classList.add('hidden')
    this.storesCountFilterTarget.classList.add('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }

  showCategoryResetButton(){
    this.categoriesResetFilterTarget.classList.remove('hidden')
    this.categoriesCountFilterTarget.classList.remove('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }

  showColorResetButton(){
    this.colorsResetFilterTarget.classList.remove('hidden')
    this.colorsCountFilterTarget.classList.remove('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }

  showProducerResetButton(){
    this.producersResetFilterTarget.classList.remove('hidden')
    this.producersCountFilterTarget.classList.remove('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }

  showStoreResetButton(){
    const checkedCount = this.storeFilterTargets.filter(checkbox => checkbox.checked).length;
    this.storesCountFilterTarget.textContent = checkedCount;

    if (checkedCount == 0){
      this.storesResetFilterTarget.classList.add('hidden')
      this.storesCountFilterTarget.classList.add('hidden')
    } else {
      this.storesResetFilterTarget.classList.remove('hidden')
      this.storesCountFilterTarget.classList.remove('hidden')
    }

    this.submitButtonTarget.classList.remove('hidden')
  }

  showMarketplaceResetButton(event) {
    const parentContainer = event.target.closest('[data-controller="reveal"]');
    if (!parentContainer) return;

    const resetButton = parentContainer.querySelector('[data-monitoring--filter-target="monitoringStoresResetFilter"]');
    const countInfo = parentContainer.querySelector('[data-monitoring--filter-target="monitoringCountResetFilter"]');
    const checkboxes = [...parentContainer.querySelectorAll('input[data-monitoring--filter-target="monitoringStoreFilter"]')];

    const checkedCount = checkboxes.filter(checkbox => checkbox.checked).length;
    if (countInfo) {
      countInfo.textContent = checkedCount;

      if (checkedCount == 0 && resetButton){
        countInfo.classList.add('hidden');
        resetButton.classList.add('hidden');
      } else {
        countInfo.classList.remove('hidden');
        resetButton.classList.remove('hidden');
      }
    }

    if (this.submitButtonTarget) {
      this.submitButtonTarget.classList.remove('hidden');
    }
  }

  removeMarketPlaceStoreFilter(event){
    const parentContainer = event.target.closest('[data-controller="reveal"]');
    const resetButton = parentContainer.querySelector('[data-monitoring--filter-target="monitoringStoresResetFilter"]');
    const countInfo = parentContainer?.querySelector('[data-monitoring--filter-target="monitoringCountResetFilter"]');
    const checkboxes = parentContainer.querySelectorAll('input[data-monitoring--filter-target="monitoringStoreFilter"]');

    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });

    countInfo.classList.add('hidden')
    resetButton.classList.add('hidden')
    this.submitButtonTarget.classList.remove('hidden')
  }

  submit(){
    this.formTarget.requestSubmit()
  }
}
